<template>
  <div>
    <b-card-actions
      v-if="serviceRequest"
      :title="$t('ServiceRequest.DetailsCardTitle', { id: serviceRequest.id })"
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <b-row>
        <b-col md="4">
          <FormulateInput
            :value="serviceRequest.serviceArea.name"
            type="label"
            :label="$t('Area')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            :value="serviceRequest.serviceCategory.name"
            type="label"
            :label="$t('Categoria')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            :value="serviceRequest.createdBy"
            type="label"
            :label="$t('Solicitante')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            v-if="serviceRequest.serviceCategory.requestedFor == 'Person'"
            :value="serviceRequest.user.name"
            type="label"
            :label="$t('Beneficiário')"
          />
          <FormulateInput
            v-else
            :value="serviceRequest.store.name"
            type="label"
            :label="$t('Beneficiário')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            :value="serviceRequest.status"
            type="label"
            :label="$t('Status')"
            is-badge
            :options="serviceRequestStatusOptions"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            :value="serviceRequest.createdDate"
            type="label"
            :label="$t('Criado em')"
            filter="datetime"
          />
        </b-col>
        <b-col md="8">
          <FormulateInput
            v-model="serviceRequest.description"
            type="textarea"
            rows="20"
            :readonly="true"
            :label="$t('Descrição')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            :value="serviceRequest.documents.length"
            type="label"
            :label="$t('Anexos')"
          />
          <p
            v-for="document in serviceRequest.documents"
            :key="document.id"
          >
            <a
              :href="document.url"
              class="nav-link"
              target="_blank"
            >
              <b-img
                :src="getIconDocument(document.url)"
                height="auto"
                width="20"
                class="mr-1"
              />
              <span>{{ document.name }}</span>
            </a>

          </p>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card-actions
      v-for="approvalFlow in approvalFlows"
      :key="approvalFlow.id"
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <template slot="title">
        <FormulateInput
          type="label"
          class="card-title"
          :value="`${$t('ApprovalFlow.DetailsCardTitle', { id: approvalFlow.id })} - ${ getApprovalStatus(approvalFlow.status) }`"
          :router-to="{
            name: 'approval-flow-details',
            params: { id: approvalFlow.id },
          }"
        />
      </template>

      <b-row>
        <b-col md="12">
          <div>
            <app-timeline>
              <app-timeline-item
                v-for="step in approvalFlow.steps"
                :key="step.id"
                :variant="getStatusIcon(step.status)"
              >
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>{{ $t('ApprovalFlow.StepTitle', { sequence: step.sequence }) }} - {{ step.name }}</h6>
                  <!-- <small class="text-muted">{{ step.approvalDate | datetime }}</small> -->
                  <small class="text-muted">{{ step.elapsedTimeFormat ? getApprovalStatus(step.status) + ': ' + step.elapsedTimeFormat : '' }}<br>{{ step.durationTimeFormat ? 'Duração: ' + step.durationTimeFormat : '' }}</small>
                </div>
                <p>{{ getApprovalStatus(step.status) }} {{ step.approvedBy ? 'por ' + step.approvedBy : '' }}</p>
                <!-- <p>{{ getGroupNameApproval(step.approvalGroups) }}</p> -->
                <div
                  v-for="group in step.approvalGroups"
                  :key="group.id"
                >
                  <span
                    v-for="approval in group.approvals"
                    :key="approval.id"
                  >
                    <p
                      v-if="approval.observation"
                    >
                      {{ $t('Observação') }} de {{ approval.responsibleUserName }}: {{ approval.observation }}
                    </p>
                  </span>
                </div>

                <p
                  v-for="document in step.documents"
                  :key="document.id"
                >
                  <a
                    :href="document.url"
                    class="nav-link"
                    target="_blank"
                  >
                    <b-img
                      :src="getIconDocument(document.url)"
                      height="auto"
                      width="20"
                      class="mr-1"
                    />
                    <span>{{ document.name }}</span>
                  </a>

                </p>
                <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
                  <!-- 1st Col -->
                  <div class="mb-1 mb-sm-0">
                    <span
                      v-if="step.approvalType == 'Owner'"
                      class="text-muted mb-50"
                    >Solicitante</span>
                    <span
                      v-if="step.approvalType == 'Groups'"
                      class="text-muted mb-50"
                    >Grupos</span>
                    <div>
                      <b-avatar
                        v-for="group in step.approvalGroups"
                        :key="group.id"
                        v-b-tooltip.hover
                        :text="group.approvalGroup.name.charAt(0)"
                        :title="group.approvalGroup.name"
                        class="mr-50 pull-up"
                        size="24"
                        variant="light-primary"
                      />
                      <b-avatar
                        v-if="step.approvalType == 'Owner'"
                        v-b-tooltip.hover
                        :text="step.approvalFlowTransactionApproval.responsibleUserName.charAt(0)"
                        :title="step.approvalFlowTransactionApproval.responsibleUserName"
                        class="mr-50 pull-up"
                        size="24"
                        variant="light-primary"
                      />
                    </div>
                  </div>
                  <!-- 2nd Col -->
                  <!-- <div class="mb-1 mb-sm-0">
                    <span class="text-muted mb-50 d-block">Deadline</span>
                    <span>20 Dec 2077</span>
                  </div> -->
                  <!-- 3rd Col -->
                  <!-- <div>
                    <span class="text-muted mb-50 d-block">Budget</span>
                    <span>$50,000</span>
                  </div> -->
                </div>
              </app-timeline-item>
            </app-timeline>
          </div>
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      main-icon="keyboard_command_key"
      :actions="actions"
      :fixed-tooltip="true"
      @cancel="cancel()"
    />
  </div>
</template>

<script>
import { BRow, BCol, BImg, BAvatar, VBToggle, VBTooltip } from 'bootstrap-vue'
import fab from '@/views/components/FAB.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { stringUtils, serviceTypes, documentIcons, approvalFlowDomains } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    fab,
    AppTimeline,
    AppTimelineItem,
    BImg,
    BAvatar,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },

  mixins: [stringUtils, serviceTypes, documentIcons, approvalFlowDomains],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('auth', ['userData']),
    ...mapState('pages/requests/serviceRequestDetails', ['serviceRequest', 'approvalFlows']),
    actions() {
      return [
        {
          name: 'cancel',
          icon: 'arrow_back',
          color: 'red',
          tooltip: this.$t('Voltar'),
        },
      ].filter(a => !a.hide)
    },
  },

  async mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/requests/serviceRequestDetails', [
      'cleanState',
      'fetchServiceRequest',
      'fetchApprovalFlows',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchServiceRequest(this.$router.currentRoute.params.id)
          await this.fetchApprovalFlows(this.$router.currentRoute.params.id)
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    cancel() {
      this.$router.push({ name: 'service-request-list' })
    },
    getApprovalStatus(status){
      return this.approvalFlowLabel[status]
    },
    getStatusIcon(status) {
      switch (status) {
        case 'InProgress':
          return 'info'
        case 'Disapproved':
        case 'Canceled':
          return 'danger'
        case 'ChangesRequested':
        case 'Holding':
          return 'warning'
        default:
          return 'success'
      }
    },
    getGroupNameApproval(approvalGroups) {
      let approvalsArray = ""
        approvalGroups.forEach(approvalGroup => {
          switch (approvalGroup.status) {
            case 'InProgress':
              approvalsArray += `Em andamento com ${approvalGroup.approvalGroup.name}\n`
              break
            case 'Disapproved':
              approvalsArray += `Reprovado por ${approvalGroup.approvalGroup.name}\n`
              break
            case 'Canceled':
              approvalsArray += `Cancelado por ${approvalGroup.approvalGroup.name}\n`
              break
            case 'ChangesRequested':
              approvalsArray += `Solicitado alteração por ${approvalGroup.approvalGroup.name}\n`
              break
            case 'Holding':
              approvalsArray += `Aguardando aprovação de ${approvalGroup.approvalGroup.name}\n`
              break
            default:
              approvalsArray += `Aprovado por ${approvalGroup.approvalGroup.name}\n`
              break
          }
        })
      return approvalsArray
    },
    getCurrentApproval(step) {
      const approvalsArray = []
        step.approvalGroups.forEach(approvalGroup => {
          // Para cada approvalGroup, adicione todos os objetos dentro de "approvals" ao array final
          approvalsArray.push(...approvalGroup.approvals)
        })
      return approvalsArray.find(
        approval =>
          approval.status === 'InProgress'
      )
    },
  },
}
</script>
